<template>
  <div class="Carrousel" v-if="numSlost" :style="`--items:${_settings.slidesToShow}`">
    <VueSlickCarousel ref="carrousel" v-bind="_settings"><slot></slot></VueSlickCarousel>
    <ArrowsControl v-if="!$isMobile && _settings.arrows" @next="$refs.carrousel.next()" @prev="$refs.carrousel.prev()" />
  </div>
</template>

<script>
export default {
  props: ["settings"],
  methods: {
    next: function() {
      this.$refs.carrousel.next();
    },
    prev: function() {
      this.$refs.carrousel.prev();
    },
  },
  computed: {
    numSlost: function() {
      return this.$slots && this.$slots?.default && this.$slots?.default?.length;
    },
    _settings: function() {
      return {
        arrows: false,
        dots: false,
        mobileFirst: true,
        initialSlide: 0,
        slidesToShow: (this.settings && this.settings.items) || 3,
        slidesToScroll: (this.settings && this.settings.items) || 3,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              arrows: false,
            },
          },
        ],
        ...(this.settings || {}),
      };
    },
  },
};
</script>

<style lang="scss">
.Carrousel {
  position: relative;

  > .slick-slider {
    padding: 0 0 0 0;
  }

  .slick-list > .slick-track {
    display: flex;
    white-space: nowrap;
  }

  .slick-list > .slick-track::before,
  .slick-list > .slick-track::after {
    display: none;
  }
}
</style>
